






























import {Component, Ref, Vue} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import NotificationService from "@/services/NotificationService"
import Notification from "@/models/Notification"
import LangModule from "@/store/LangModule"

@Component
export default class NotificationsView extends Vue {
	@Ref() readonly form!: HTMLFormElement
	lang: any = getModule(LangModule).lang
	loading: boolean = false
	notification: Notification = new Notification()

	created() {
		NotificationService.getNotification(this, Number(this.$route.params.id))
	}

	sendNotification() {
		// if (this.form.validate()) {
		// 	getModule(DialogModule).showDialog(new Dialog(
		// 		 this.lang.warning, this.lang.sendNotificationConfirmation,
		// 		 () => NotificationService.postNotification(this, this.notification.receivers!, this.notification.subject!, this.notification.message!)
		// 	))
		// }
	}

}
